import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';

import Footer from 'components/Footer';
import Layout from 'components/Layout';
import SEO from 'components/SEO';

const IndexPage = (): JSX.Element => {
  
  const data = useStaticQuery(graphql`
  query {
    text: contentfulProject(title: {eq: "Przed drugim spotkaniem"}) {
      title
      additionalInfo {
        json
      }
    }
    wave: contentfulAsset(title: {eq: "Path 210"}) {
      fluid(maxWidth: 1920) {
        ...GatsbyContentfulFluid_noBase64
      }
    }
  }
  `);
  return (
    <Layout>
      <SEO title="Warsztaty dzień 1" />
      <div className="workshop-info">
        <h1>{data.text.title}</h1>
        {documentToReactComponents(data.text.additionalInfo.json)}
        <Img className="wave wave--bottom" fluid={data.wave.fluid} />
      </div>
      <Footer />
    </Layout>
  );
};

export default IndexPage;
